import Coaches from "../Staff/Coaches";
import y2023t14 from "../../components/img/teams/2023-14.jpg";
import y2023t141b from "../../components/img/teams/2023-141-b.jpeg";
import y2023t151b from "../../components/img/teams/2023-151-b.jpg";
import y2023t16 from "../../components/img/teams/2023-16.jpg";
import y2023t161b from "../../components/img/teams/2023-161-b.jpg";
import y2023t162b from "../../components/img/teams/2023-162-b.jpg";
import y2023t163b from "../../components/img/teams/2023-163-b.jpeg";
import y2023t171b from "../../components/img/teams/2023-171-b.JPG";
import y2023t181b from "../../components/img/teams/2023-181-b.JPG";
import y2023t182b from "../../components/img/teams/2023-182-b.JPG";
import y2024t121b from "../../components/img/teams/2024-121-b.JPG";
import y2024t141 from "../../components/img/teams/2024-141.jpg";
import y2024t141b from "../../components/img/teams/2024-141-b.JPG";
import y2024t142 from "../../components/img/teams/2024-142.jpg";
import y2024t151 from "../../components/img/teams/2024-151.jpg";
import y2024t151b from "../../components/img/teams/2024-151-b.JPG";
import y2024t152 from "../../components/img/teams/2024-152.jpg";
import y2024t153 from "../../components/img/teams/2024-153.jpg";
import y2024t161b from "../../components/img/teams/2024-161-b.JPG";
import y2024t162b from "../../components/img/teams/2024-162-b.JPG";
import y2024t163b from "../../components/img/teams/2024-163-b.JPG";
import y2024t171 from "../../components/img/teams/2024-171.jpg";
import y2024t171b from "../../components/img/teams/2024-171-b.JPG";
import y2024t172 from "../../components/img/teams/2024-172.jpg";
import y2024t172b from "../../components/img/teams/2024-172-b.JPG";
import y2024t182b from "../../components/img/teams/2024-182-b.JPG";
import y2024t183b from "../../components/img/teams/2024-183-b.JPG";

const TeamList = [
	/* CURRENT TEAMS */

	//Girls 25 17-1
	{
		teamName: "17-1",
		age: 17,
		rank: 1,
		heads: [Coaches.kneerhof],
		assistants: [Coaches.alopez],
		roster: [
			{
				name: "Marlee Hughes",
				height: "5' 8\"",
				gradYear: "2027",
				school: "Safford",
				number: 8,
			},
			{
				name: "Mia Mendoza",
				height: "5' 5\"",
				gradYear: "2026",
				school: "Catalina Foothills",
				number: 5,
			},
			{
				name: "Amerie Green",
				height: "5' 5\"",
				gradYear: "2026",
				school: "Tucson",
				number: 3,
			},
			{
				name: "Piper Kukla",
				height: "5' 6\"",
				gradYear: "2026",
				school: "University",
				number: 6,
			},
			{
				name: "Josephine Mazerski",
				height: "5' 0\"",
				gradYear: "2026",
				school: "Tucson",
				number: 9,
			},
			{
				name: "Elliana Mathewson",
				height: "5' 7\"",
				gradYear: "2027",
				school: "University",
				number: 13,
			},
			{
				name: "Isabella Garcia",
				height: "5' 5\"",
				gradYear: "2026",
				school: "University",
				number: 7,
			},
			{
				name: "Samantha Benson",
				height: "5' 5\"",
				gradYear: "2026",
				school: "Sahuaro",
				number: 2,
			},
			{
				name: "Sophia Mooney",
				height: "5' 3\"",
				gradYear: "2026",
				school: "Mica Mountain",
				number: 14,
			},
			{
				name: "Kasia Carley",
				height: "5' 5\"",
				gradYear: "2026",
				school: "Tucson",
				number: 16,
			},
			{
				name: "Stephanie Pina",
				height: "5' 2\"",
				gradYear: "2026",
				school: "Tucson",
				number: 22,
			},
			{
				name: "Adalyn Chue",
				height: "5' 4\"",
				gradYear: "2026",
				school: "University",
				number: 4,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 16-1
	{
		teamName: "16-1",
		age: 16,
		rank: 1,
		heads: [Coaches.jserna],
		assistants: [Coaches.sgillman],
		roster: [
			{
				name: "Jayleen Lizarraga",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Sunnyside",
				number: 1,
			},
			{
				name: "Victoria Oceguera",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
				number: 10,
			},
			{
				name: "Kayla Salazar",
				height: "5' 3\"",
				gradYear: "2027",
				school: "Marana",
				number: 24,
			},
			{
				name: "Sophia Washburn",
				height: "5' 3\"",
				gradYear: "2026",
				school: "St. Augustine",
				number: 8,
			},
			{
				name: "Alexie Danna",
				height: "5' 6\"",
				gradYear: "2027",
				school: "The Gregory School",
				number: 31,
			},
			{
				name: "Kandhy Estrada",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Sunnyside",
				number: 3,
			},
			{
				name: "Alyssa Nitka",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Salpointe",
				number: 15,
			},
			{
				name: "Alexa Mayorquin",
				height: "5' 2\"",
				gradYear: "2027",
				school: "Catalina Foothills",
				number: 2,
			},
			{
				name: "Rebecca Vazquez",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Catalina Foothills",
				number: 18,
			},
			{
				name: "Camri Whitaker",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
				number: 16,
			},
			{
				name: "Sophia Maytorena",
				height: "5' 4\"",
				gradYear: "2027",
				school: "Marana",
				number: 6,
			},
			{
				name: "Karime Salette Bracamonte",
				height: "5' 4\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
				number: 14,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 16-2
	{
		teamName: "16-2",
		age: 16,
		rank: 2,
		heads: [Coaches.rbautista],
		assistants: [Coaches.slopez],
		roster: [
			{
				name: "Alexia Franco",
				height: "5' 4\"",
				gradYear: "2027",
				school: "Pueblo",
				number: 9,
			},
			{
				name: "Valeria Leon",
				height: "5' 3\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
				number: 11,
			},
			{
				name: "Anahi Miranda Peregrina",
				height: "5' 5\"",
				gradYear: "2026",
				school: "Sahuaro ",
				number: 8,
			},
			{
				name: "Aiden Kearney",
				height: "5' 3\"",
				gradYear: "2027",
				school: "Mountain View",
				number: 21,
			},
			{
				name: "Camila Leyvas",
				height: "5' 3\"",
				gradYear: "2027",
				school: "Mountain View",
				number: 12,
			},
			{
				name: "Chelsea Lopez",
				height: "5' 2\"",
				gradYear: "2027",
				school: "University",
				number: 6,
			},
			{
				name: "Hannah Lopez",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Sunnyside",
				number: 1,
			},
			{
				name: "Fernanda Partida Guillen",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Catalina Foothills",
				number: 15,
			},
			{
				name: "Olivia Little",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Sahuaro",
				number: 3,
			},
			{
				name: "Maya Rocha",
				height: "5' 4\"",
				gradYear: "2027",
				school: "University",
				number: 7,
			},
			{
				name: "Ariely Torres",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Tucson",
				number: 23,
			},
			{
				name: "Samara Rodriguez",
				height: "5' 0\"",
				gradYear: "2027",
				school: "Desert Christian",
				number: 10,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 15-1
	{
		teamName: "15-1",
		age: 15,
		rank: 1,
		heads: [Coaches.kwells],
		assistants: [Coaches.adewitt],
		roster: [
			{
				name: "Kayleigh Kennedy",
				height: "5' 4\"",
				gradYear: "2028",
				school: "Canyon Del Oro",
				number: 34,
			},
			{
				name: "Isabella Ori",
				height: "5' 6\"",
				gradYear: "2028",
				school: "BASIS",
				number: 1,
			},
			{
				name: "Kinley Noggle",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Marana",
				number: 5,
			},
			{
				name: "Lilly Hardy",
				height: "5' 6\"",
				gradYear: "2028",
				school: "Marana",
				number: 16,
			},
			{
				name: "Emily Stuck",
				height: "5' 4\"",
				gradYear: "2028",
				school: "Mica Mountain",
				number: 7,
			},
			{
				name: "Zoey Morgan",
				height: "5' 5\"",
				gradYear: "2028",
				school: "Tucson",
				number: 14,
			},
			{
				name: "Brookelyn Earven",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Red Rock ",
				number: 4,
			},
			{
				name: "Jayden Hall",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Cienega",
				number: 17,
			},
			{
				name: "Julia Richards",
				height: "5' 6\"",
				gradYear: "2028",
				school: "Marana",
				number: 13,
			},
			{
				name: "Isabella Riggi",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Marana",
				number: 11,
			},
			{
				name: "Daniela Tavares-Serrano",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Mountain View",
				number: 6,
			},
			{
				name: "Phoebe Tittle",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Amphitheater",
				number: 12,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 15-2
	{
		teamName: "15-2",
		age: 15,
		rank: 2,
		heads: [Coaches.jdevries],
		assistants: [Coaches.kmccarthy],
		roster: [
			{
				name: "Olivia Harding",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Marana",
				number: 8,
			},
			{
				name: "Piper Minyard",
				height: "5' 9\"",
				gradYear: "2028",
				school: "Sahuaro",
				number: 1,
			},
			{
				name: "Emme Baker",
				height: "5' 0\"",
				gradYear: "2028",
				school: "Mountain View",
				number: 12,
			},
			{
				name: "Tiffany Anderson",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Marana",
				number: 15,
			},
			{
				name: "Brianna Abalos",
				height: "5' 3\"",
				gradYear: "2029",
				school: "Mountain View",
				number: 13,
			},
			{
				name: "Kenadie Barnes",
				height: "5' 1\"",
				gradYear: "2028",
				school: "Cienega",
				number: 16,
			},
			{
				name: "Ameliana Herman",
				height: "5' 0\"",
				gradYear: "2028",
				school: "Marana",
				number: 2,
			},
			{
				name: "Bailey McCoy",
				height: "5' 2\"",
				gradYear: "2028",
				school: "Cienega",
				number: 88,
			},
			{
				name: "Sarah Teusch",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Cienega",
				number: 3,
			},
			{
				name: "Gabriella Pedrego",
				height: "5' 3\"",
				gradYear: "2028",
				school: "Sahuaro",
				number: 4,
			},
			{
				name: "Samara Schacht",
				height: "5' 5\"",
				gradYear: "2028",
				school: "Canyon Del Oro",
				number: 18,
			},
			{
				name: "Lola Diaz",
				height: "5' 0\"",
				gradYear: "2028",
				school: "Mica Mountain",
				number: 42,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 14-1
	{
		teamName: "14-1",
		age: 14,
		rank: 1,
		heads: [Coaches.krountreee],
		assistants: [Coaches.frucker],
		roster: [
			{
				name: "Emily Gonzalez",
				number: 8,
				height: "4' 11\"",
				gradYear: "2029",
				school: "Old Vail",
			},
			{
				name: "Grace Markley",
				number: 15,
				height: "5' 7\"",
				gradYear: "2029",
				school: "Old Vail",
			},
			{
				name: "Audriana Alegria",
				number: 2,
				height: "5' 4\"",
				gradYear: "2029",
				school: "St. Michael's",
			},
			{
				name: "Harley Bowers",
				number: 7,
				height: "5' 4\"",
				gradYear: "2029",
				school: "Orange Grove",
			},
			{
				name: "Leylani Chavez",
				number: 13,
				height: "5' 2\"",
				gradYear: "2030",
				school: "Old Vail",
			},
			{
				name: "Lyxenia Rocha",
				number: 20,
				height: "5' 4\"",
				gradYear: "2029",
				school: "St. Cyril",
			},
			{
				name: "Isella Hernandez",
				number: 14,
				height: "5' 2\"",
				gradYear: "2029",
				school: "Old Vail",
			},
			{
				name: "Alexandra Arias-Kortright",
				number: 1,
				height: "5' 2\"",
				gradYear: "2028",
				school: "Catalina Foothills",
			},
			{
				name: "Kallah Leyva",
				number: 10,
				height: "5' 3\"",
				gradYear: "2029",
				school: "Orange Grove",
			},
			{
				name: "Cyana Dicochea",
				number: 24,
				height: "5' 2\"",
				gradYear: "2028",
				school: "St. John",
			},
			{
				name: "Veronica Cheung",
				number: 11,
				height: "5' 2\"",
				gradYear: "2028",
				school: "Orange Grove",
			},
			{
				name: "Aryana Diaz",
				number: 3,
				height: "5' 0\"",
				gradYear: "2029",
				school: "Old Vail",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 14-2
	{
		teamName: "14-2",
		age: 14,
		rank: 2,
		heads: [Coaches.amolina],
		assistants: [Coaches.mvillegas],
		roster: [
			{
				name: "Liliana Spinner",
				number: 14,
				height: "5' 3\"",
				gradYear: "2029",
				school: "Old Vail",
			},
			{
				name: "Ariana Samain",
				number: 27,
				height: "5' 5\"",
				gradYear: "2029",
				school: "Orange Grove",
			},
			{
				name: "Lily Hidalgo",
				number: 10,
				height: "5' 3\"",
				gradYear: "2028",
				school: "Red Rock",
			},
			{
				name: "Isabella Cuadra",
				number: 8,
				height: "5' 4\"",
				gradYear: "2029",
				school: "Esperero Canyon",
			},
			{
				name: "Miranda Ibarra",
				number: 1,
				height: "4' 11\"",
				gradYear: "2029",
				school: "Mary Belle McCorkle",
			},
			{
				name: "Alexandria Harvey",
				number: 6,
				height: "5' 4\"",
				gradYear: "2029",
				school: "Orange Grove",
			},
			{
				name: "Alany Manjarrez",
				number: 11,
				height: "5' 2\"",
				gradYear: "2029",
				school: "Leman",
			},
			{
				name: "Leah Ghotmi",
				number: 5,
				height: "5' 8\"",
				gradYear: "2029",
				school: "Orange Grove",
			},
			{
				name: "Giana Chairez-Montano",
				number: 3,
				height: "5' 1\"",
				gradYear: "2029",
				school: "Dodge Traditional",
			},
			{
				name: "Alessandra Ortiz",
				number: 13,
				height: "5' 2\"",
				gradYear: "2029",
				school: "Old Vail",
			},
			{
				name: "Sophie Hidalgo",
				number: 7,
				height: "5' 1\"",
				gradYear: "2029",
				school: "Red Rock",
			},
			{
				name: "Ella Luce",
				height: "5' 0\"",
				gradYear: "2030",
				school: "Esmond Station",
				number: 45,
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Girls 25 13-1
	{
		teamName: "13-1",
		age: 13,
		rank: 1,
		heads: [Coaches.ahoover],
		assistants: [Coaches.alang],
		roster: [
			{
				name: "Monica Moraga",
				number: 30,
				height: "5' 3\"",
				gradYear: "2030",
				school: "Marana Middle",
			},
			{
				name: "Samantha Trejo",
				number: 14,
				height: "5' 4\"",
				gradYear: "2030",
				school: "The Gregory School",
			},
			{
				name: "Naomi Hudgens",
				number: 1,
				height: "5' 4\"",
				gradYear: "2030",
				school: "Espero Canyon",
			},
			{
				name: "Georgianna Meier",
				number: 28,
				height: "5' 6\"",
				gradYear: "2030",
				school: "The Gregory School",
			},
			{
				name: "Eleanor Hudgens",
				number: 3,
				height: "5' 3\"",
				gradYear: "2030",
				school: "Espero Canyon",
			},
			{
				name: "Nadezhda Pinder",
				number: 4,
				height: "5' 0\"",
				gradYear: "2030",
				school: "The Gregory School",
			},
			{
				name: "Isabel Lopez",
				number: 27,
				height: "5' 3\"",
				gradYear: "2030",
				school: "St. Cyril",
			},
			{
				name: "Arya Bourdeau",
				number: 13,
				height: "4' 4\"",
				gradYear: "2031",
				school: "The Gregory School",
			},
			{
				name: "Amelia McDonald",
				number: 6,
				height: "5' 5\"",
				gradYear: "2030",
				school: "Drachman",
			},
			{
				name: "Lily Kommareddi",
				number: 24,
				height: "5' 2\"",
				gradYear: "2030",
				school: "St. Michael's",
			},
			{
				name: "Sky Shade",
				number: 11,
				height: "4' 10\"",
				gradYear: "2030",
				school: "La Paloma Academy",
			},
			{
				name: "Kaitlyn Penrod",
				number: 8,
				height: "5' 8\"",
				gradYear: "2031",
				school: "Sonoran Science Academy",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Girls",
		year: "24-25",
		current: true,
	},
	//Boys Spring 25 14-1
	{
		teamName: "14-1",
		age: 14,
		rank: 1,
		heads: [Coaches.aadair],
		assistants: [Coaches.jobrambila],
		roster: [
			{
				number: 2,
				name: "Bradley King",
				height: "5' 2\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				number: 3,
				name: "Oliver Heald",
				height: "5' 2\"",
				gradYear: "2029",
				school: "Alice Vail",
			},
			{
				number: 6,
				name: "Sam Hulsey",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Esmond Station",
			},
			{
				number: 10,
				name: "Amos King",
				height: "5' 9\"",
				gradYear: "2028",
				school: "Homeschool",
			},
			{
				number: 14,
				name: "RJ Meza",
				height: "5' 8\"",
				gradYear: "2030",
				school: "St. Joseph",
			},
			{
				number: 15,
				name: "Gabriel Sadza",
				height: "6' 3\"",
				gradYear: "2029",
				school: "Dodge Traditional",
			},
			{
				number: 21,
				name: "Kai Hill",
				height: "5' 6\"",
				gradYear: "2029",
				school: "Marana Middle",
			},
			{
				number: 25,
				name: "Tanner Welch",
				height: "5' 11\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				number: 52,
				name: "James Merriman",
				height: "5' 9\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Spring",
		year: "24-25",
		current: true,
	},
	//Boys Spring 25 14-2
	{
		teamName: "14-2",
		age: 14,
		rank: 2,
		heads: [Coaches.kneerhof],
		assistants: [Coaches.sgillman],
		roster: [
			{
				number: 1,
				name: "Roman Marum",
				height: "5' 4\"",
				gradYear: "2030",
				school: "Emily Gray",
			},
			{
				number: 9,
				name: "Henry Swartz",
				height: "5' 3\"",
				gradYear: "2030",
				school: "Orange Grove",
			},
			{
				number: 11,
				name: "Nathan Saavedra",
				height: "5' 6\"",
				gradYear: "2029",
				school: "The Gregory School",
			},
			{
				number: 13,
				name: "Anthony Alcantar",
				height: "5' 5\"",
				gradYear: "2029",
				school: "Mansfeld Middle",
			},
			{
				number: 20,
				name: "Obadiah Flores-Simmons",
				height: "5' 8\"",
				gradYear: "2029",
				school: "Leman",
			},
			{
				number: 22,
				name: "Nicolas Straub Martinez",
				height: "5' 9\"",
				gradYear: "2029",
				school: "The Gregory School",
			},
			{
				number: 51,
				name: "Luke Ayoub",
				height: "5' 6\"",
				gradYear: "2029",
				school: "Homeschool",
			},
			{
				number: 99,
				name: "Toprak Karaduman",
				height: "5' 5\"",
				gradYear: "2030",
				school: "Orange Grove",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Spring",
		year: "24-25",
		current: true,
	},
	//Boys Spring 25 12-1
	{
		teamName: "12-1",
		age: 12,
		rank: 1,
		heads: [Coaches.larocha],
		assistants: [Coaches.cleary],
		roster: [
			{
				number: 3,
				name: "Rockford J",
				gradYear: "2032",
			},
			{
				number: 4,
				name: "Reed K",
				gradYear: "2030",
			},
			{
				number: 5,
				name: "Lukas L",
				gradYear: "2032",
			},
			{
				number: 6,
				name: "Volk S",
				gradYear: "2034",
			},
			{
				number: 7,
				name: "Harrison B",
				gradYear: "2033",
			},
			{
				number: 8,
				name: "Rohk S",
				gradYear: "2032",
			},
			{
				number: 10,
				name: "Brayden M",
				gradYear: "2031",
			},
			{
				number: 12,
				name: "Shivaan P",
				gradYear: "2030",
			},
			{
				number: 19,
				name: "Eli W",
				gradYear: "2031",
			},
			{
				number: 21,
				name: "Alejandro R",
				gradYear: "2031",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Spring",
		year: "24-25",
		current: true,
	},

	//Boys Fall 24 18-1
	{
		teamName: "18-1",
		age: 18,
		rank: 1,
		heads: [Coaches.hmott],
		assistants: [Coaches.rcall, Coaches.cmott],
		roster: [
			{
				number: 11,
				name: "Dylan Rubio",
				height: "6' 8\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 12,
				name: "Jacob Martinez",
				height: "6' 3\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 22,
				name: "Caiden Mott",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Cienega ",
			},
			{
				number: 23,
				name: "Jaden Clark",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 30,
				name: "Zane Schwartz",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 35,
				name: "Dawson Leber",
				height: "6' 5\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 4,
				name: "Samuel Widmer",
				height: "5' 8\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
			{
				number: 40,
				name: "Derek Zobrist-Carroll",
				height: "6' 5\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 6,
				name: "Sammy Aston",
				height: "6' 3\"",
				gradYear: "2025",
				school: "Cienega",
			},
			{
				number: 8,
				name: "Dawson Briggs",
				height: "6' 4\"",
				gradYear: "2025",
				school: "Cienega",
			},
			{
				number: 9,
				name: "Alexander Owens",
				height: "6' 9\"",
				gradYear: "2025",
				school: "Salpointe",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 18-2
	{
		teamName: "18-2",
		age: 18,
		rank: 2,
		heads: [Coaches.kneerhof],
		assistants: [Coaches.jserna, Coaches.csmith],
		roster: [
			{
				number: 1,
				name: "Tim Tran",
				height: "5' 9\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 10,
				name: "Kyle Senkerik",
				height: "6' 6\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 11,
				name: "Jairus Walker",
				height: "6' 5\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 13,
				name: "Josef Fabian",
				height: "6' 1\"",
				gradYear: "2025",
				school: "Walden Grove",
			},
			{
				number: 16,
				name: "Jayce Soto-Balin",
				height: "5' 5\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 18,
				name: "Jayden Scholz",
				height: "6' 1\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 2,
				name: "Israel Valencia",
				height: "5' 11\"",
				gradYear: "2025",
				school: "Pueblo",
			},
			{
				number: 22,
				name: "Praise King",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 24,
				name: "Josef Pletnick",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Canyon del Oro ",
			},
			{
				number: 54,
				name: "James Marshall",
				height: "5' 11\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 7,
				name: "Peace King",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 9,
				name: "Ruben Chavarin",
				height: "5' 7\"",
				gradYear: "2026",
				school: "Desert View",
			},
		],
		image: {
			src: y2024t182b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 18-3
	{
		teamName: "18-3",
		age: 18,
		rank: 3,
		heads: [Coaches.jdevries],
		assistants: [Coaches.crice],
		roster: [
			{
				number: 10,
				name: "Tanner Larsen",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 15,
				name: "Thomas Vamos",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 3,
				name: "Abrahan Carrillo",
				height: "5' 10\"",
				gradYear: "2025",
				school: "Marana",
			},
			{
				number: 5,
				name: "Caleb Muir",
				height: "5' 10\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
			{
				number: 7,
				name: "Ryder Belik",
				height: "5' 7\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 43,
				name: "Isak Jaime Sanchez",
				height: "5' 11\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 44,
				name: "Dominic Cadena",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
			{
				number: 9,
				name: "Sean Wiltshire",
				height: "6'4\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
		],
		image: {
			src: y2024t183b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 17-1
	{
		teamName: "17-1",
		age: 17,
		rank: 1,
		heads: [Coaches.lriel],
		assistants: [Coaches.avanhoesen],
		roster: [
			{
				number: 1,
				name: "Cammeron Crowley",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Salpointe ",
			},
			{
				number: 11,
				name: "Ryan Guillot",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 13,
				name: "Tyler Nitka",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 14,
				name: "Andrew Evans",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 17,
				name: "Nicolas Sanchez",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				number: 21,
				name: "Payton Needham",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 23,
				name: "Thor Haldorsen",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 3,
				name: "Arthur Brock",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 4,
				name: "Jayce Rooney",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 6,
				name: "Reyes Gardner",
				height: "5' 8\"",
				gradYear: "2026",
				school: "Salpointe ",
			},
			{
				number: 7,
				name: "Elijah Gutierrez",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				number: 8,
				name: "Connor Daggett",
				height: "6' 5\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
		],
		image: {
			src: y2024t171b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 17-2
	{
		teamName: "17-2",
		age: 17,
		rank: 2,
		heads: [Coaches.mvillegas],
		assistants: [Coaches.akeen],
		roster: [
			{
				number: 1,
				name: "Patrick Sapp",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 10,
				name: "Graham Jacob",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 11,
				name: "Gabriel Harmon",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Tucson High",
			},
			{
				number: 15,
				name: "Vaughan Lancaster",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Tucson",
			},
			{
				number: 19,
				name: "Nathan Pollack",
				height: "5' 8\"",
				gradYear: "2026",
				school: "University",
			},
			{
				number: 3,
				name: "Sutton Heisey",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 4,
				name: "Reegan Wilson",
				height: "5' 9\"",
				gradYear: "2026",
				school: "Amphitheater",
			},
			{
				number: 7,
				name: "Samson Goldberg",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 8,
				name: "Will Luxbacher",
				height: "6' 5\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 12,
				name: "Arthur Pina",
				height: "5'11\"",
				gradYear: "2026",
				school: "Tucson",
			},
			{
				number: 88,
				name: "Kien McCoy",
				height: "5' 9\"",
				gradYear: "2026",
				school: "Cienega",
			},
		],
		image: {
			src: y2024t172b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 16-1
	{
		teamName: "16-1",
		age: 16,
		rank: 1,
		heads: [Coaches.lrocha],
		assistants: [Coaches.shuff, Coaches.fdonley],
		roster: [
			{
				number: 10,
				name: "Smith King",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Homeschool",
			},
			{
				number: 11,
				name: "Andrew Keen",
				height: "6' 1\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 13,
				name: "Antonio Sabelli",
				height: "6' 3\"",
				gradYear: "2027",
				school: "University",
			},
			{
				number: 14,
				name: "Lehi Rocha",
				height: "6' 4\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 18,
				name: "Jack Taras",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 3,
				name: "Chase Briggs",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 45,
				name: "Shane Palmer",
				height: "6' 2\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 5,
				name: "Tyson Tippett",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Cienega ",
			},
			{
				number: 6,
				name: "Frankie Maturo",
				height: "5' 4\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 7,
				name: "Ethan Pinkney",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 9,
				name: "Antony Owens",
				height: "6' 1\"",
				gradYear: "2027",
				school: "Salpointe",
			},
		],
		image: {
			src: y2024t161b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 16-2
	{
		teamName: "16-2",
		age: 16,
		rank: 2,
		heads: [Coaches.krountreee],
		assistants: [Coaches.afelix, Coaches.jchapman],
		roster: [
			{
				number: 10,
				name: "Tyler Drummond",
				height: "5' 10\"",
				gradYear: "2027",
				school: "University",
			},
			{
				number: 11,
				name: "Cuyler Zeh Plunkett",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 13,
				name: "Malcolm Frank",
				height: "6' 1\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 14,
				name: "Gregan Vamos",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 15,
				name: "Ian Dahl",
				height: "5' 8\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
			},
			{
				number: 2,
				name: "Nolan Incontrera",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 21,
				name: "Luke Evans",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Salpointe ",
			},
			{
				number: 3,
				name: "Julian Merheb",
				height: "6' 2\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 7,
				name: "Asher Taton",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 8,
				name: "William Elliott",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Marana",
			},
			{
				number: 9,
				name: "Kai Bowles",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 12,
				name: "Santiago Morimoto",
				height: "5'10\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
		],
		image: {
			src: y2024t162b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	// Boys Fall 24 16-3
	{
		teamName: "16-3",
		age: 16,
		rank: 3,
		heads: [Coaches.temma],
		assistants: [Coaches.mburke],
		roster: [
			{
				number: 1,
				name: "Ethan Belhumeur",
				height: "5' 7\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 10,
				name: "Jackson Buntin",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 11,
				name: "Mason Chupp",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
			},
			{
				number: 12,
				name: "Dakota Gossmiller",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 14,
				name: "Ethan Lynch",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Tucson",
			},
			{
				number: 15,
				name: "Jacob Chapman",
				height: "6' 4\"",
				gradYear: "2027",
				school: "Marana",
			},
			{
				number: 18,
				name: "Fin Keller",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 24,
				name: "Joaquim Pama",
				height: "5'2\"",
				gradYear: "2027",
				school: "BASIS",
			},
			// {
			//   number: 2,
			//   name: "Abner Duarte Bautista",
			//   height: "5' 4\"",
			//   gradYear: "2027",
			//   school: "Tucson",
			// },
			{
				number: 25,
				name: "Jakob Velasco",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 4,
				name: "Richard Yellott",
				height: "5' 10\"",
				gradYear: "2027",
				school: "The Gregory School",
			},
			{
				number: 5,
				name: "Max Manzo",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 8,
				name: "Marcus Garcia",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Cienega",
			},
		],
		image: {
			src: y2024t163b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 15-1
	{
		teamName: "15-1",
		age: 15,
		rank: 1,
		heads: [Coaches.kwells],
		assistants: [Coaches.amolina, Coaches.lmcadams],
		roster: [
			{
				number: 10,
				name: "Amos King",
				height: "5' 9\"",
				gradYear: "2028",
				school: "Homeschool",
			},
			{
				number: 13,
				name: "Valentino Molina",
				height: "5' 11\"",
				gradYear: "2028",
				school: "Cienega",
			},
			{
				number: 14,
				name: "Leo Spaulding",
				height: "5' 4\"",
				gradYear: "2028",
				school: "Catalina Foothills",
			},
			{
				number: 16,
				name: "Xavier Molina",
				height: "5' 6\"",
				gradYear: "2028",
				school: "Cienega",
			},
			{
				number: 18,
				name: "Peter Greer",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Catalina Foothills",
			},
			{
				number: 27,
				name: "Jael Soto-Perez",
				height: "5' 9\"",
				gradYear: "2028",
				school: "Desert Christian",
			},
			{
				number: 28,
				name: "Theodore LeBlanc",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Catalina Foothills",
			},
			{
				number: 3,
				name: "Donovan Leber",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Salpointe",
			},
			{
				number: 4,
				name: "Vaughn Rooney",
				height: "5' 8\"",
				gradYear: "2028",
				school: "Catalina Foothills",
			},
			{
				number: 5,
				name: "Wesley Roberts",
				height: "6' 0\"",
				gradYear: "2028",
				school: "Marana",
			},
			{
				number: 52,
				name: "James Merriman",
				height: "5' 9\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				number: 8,
				name: "Vincent Merheb",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Esperero Canyon",
			},
		],
		image: {
			src: y2024t151b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 14-1
	{
		teamName: "14-1",
		age: 14,
		rank: 1,
		heads: [Coaches.rbautista],
		assistants: [Coaches.frucker],
		roster: [
			{
				number: 11,
				name: "Andre Crawfurd",
				height: "5' 6\"",
				gradYear: "2028",
				school: "Mansfeld Middle",
			},
			{
				number: 42,
				name: "Reese Elkins",
				height: "5' 4\"",
				gradYear: "2028",
				school: "University",
			},
			{
				number: 14,
				name: "RJ Meza",
				height: "5' 7\"",
				gradYear: "2030",
				school: "St. Joseph",
			},
			{
				number: 2,
				name: "Bradley King",
				height: "4' 8\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				number: 3,
				name: "Gavin Elkins",
				height: "5' 3\"",
				gradYear: "2028",
				school: "University",
			},
			{
				number: 32,
				name: "Samuel Gonzalez",
				height: "5' 5\"",
				gradYear: "2030",
				school: "Doolen",
			},
			{
				number: 51,
				name: "Luke Ayoub",
				height: "5' 6\"",
				gradYear: "2029",
				school: "Homeschool",
			},
			{
				number: 6,
				name: "Sam Hulsey",
				height: "5' 4\"",
				gradYear: "2028",
				school: "Esmond Station",
			},
			{
				number: 66,
				name: "Cole Tyler",
				height: "5' 1\"",
				gradYear: "2030",
				school: "Cross",
			},
			{
				number: 7,
				name: "Chase Ordonez",
				height: "5' 2\"",
				gradYear: "2029",
				school: "Dove Mountain",
			},
			{
				number: 9,
				name: "Henry Swartz",
				height: "5' 3\"",
				gradYear: "2030",
				school: "Orange Grove",
			},
		],
		image: {
			src: y2024t141b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},
	//Boys Fall 24 12-1
	{
		teamName: "12-1",
		age: 12,
		rank: 1,
		heads: [Coaches.bbowers],
		assistants: [Coaches.sgillman],
		roster: [
			{
				number: 1,
				name: "Roman",
				gradYear: "2030",
			},
			{
				number: 2,
				name: "Ian",
				gradYear: "2032",
			},
			{
				number: 3,
				name: "Rocky",
				gradYear: "2032",
			},
			{
				number: 4,
				name: "Reed",
				gradYear: "2030",
			},
			{
				number: 5,
				name: "Lukas",
				gradYear: "2032",
			},
			{
				number: 7,
				name: "Harrison",
				gradYear: "2033",
			},
			{
				number: 8,
				name: "Rohk",
				gradYear: "2032",
			},
			{
				number: 9,
				name: "Dane",
				gradYear: "2033",
			},
			{
				number: 10,
				name: "Brayden",
				gradYear: "2029",
			},
			{
				number: 11,
				name: "Crew",
				gradYear: "2033",
			},
			{
				number: 41,
				name: "Colt",
				gradYear: "2030",
			},
		],
		image: {
			src: y2024t121b,
		},
		season: "Boys",
		sub: "Fall",
		year: "24-25",
		current: true,
	},

	/* ARCHIVED TEAMS */

	/********/
	/* 2024 */
	/********/

	// Boys 2024 Summer 18-1
	{
		teamName: "18-1",
		age: 18,
		rank: 1,
		heads: [Coaches.hmott, Coaches.drubio],
		assistants: [Coaches.cmott],
		roster: [
			{
				number: 12,
				name: "Jacob Martinez",
				height: "6' 2\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 17,
				name: "Dylan Rubio",
				height: "6' 7\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 2,
				name: "Dawson Briggs",
				height: "6' 3\"",
				gradYear: "2025",
				school: "Cienega",
			},
			{
				number: 22,
				name: "Caiden Mott",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Cienega ",
			},
			{
				number: 3,
				name: "Dominic Smith",
				height: "6' 2\"",
				gradYear: "2024",
				school: "Ironwood Ridge",
			},
			{
				number: 44,
				name: "Josef Fabian",
				height: "6' 1\"",
				gradYear: "2025",
				school: "Walden Grove",
			},
			{
				number: 99,
				name: "Jorge Franco",
				height: "6' 3\"",
				gradYear: "2024",
				school: "Pueblo",
			},
			{
				number: 30,
				name: "Zane Schwartz",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 66,
				name: "Samuel Widmer",
				height: "5' 8\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
			{
				number: 88,
				name: "Wilson Spaulding",
				height: "6' 3\"",
				gradYear: "2024",
				school: "Catalina Foothills",
			},
			{
				number: 77,
				name: "Titus Forrest",
				height: "6' 4\"",
				gradYear: "2024",
				school: "Homeschool",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 18-2
	{
		teamName: "18-2",
		age: 18,
		rank: 2,
		heads: [Coaches.shuff],
		assistants: [Coaches.mburke, Coaches.avanhoesen, Coaches.jserna],
		roster: [
			{
				number: 10,
				name: "Kyle Senkerik",
				height: "6' 4\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 18,
				name: "Jayden Scholz",
				height: "6' 1\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 2,
				name: "Israel Valencia",
				height: "5' 10\"",
				gradYear: "2025",
				school: "Pueblo",
			},
			{
				number: 38,
				name: "Peace King",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 39,
				name: "Praise King",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 1,
				name: "Jeremiah Edwards",
				height: "6' 0\"",
				gradYear: "2024",
				school: "Salpointe",
			},
			{
				number: 7,
				name: "Timothy Tran",
				height: "5' 9\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 43,
				name: "Torin Claggett",
				height: "6' 5\"",
				gradYear: "2024",
				school: "Catalina Foothills",
			},
			{
				number: 4,
				name: "Johan Brambila",
				height: "5' 4\"",
				gradYear: "2024",
				school: "University",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 17-1
	{
		teamName: "17-1",
		age: 17,
		rank: 1,
		heads: [Coaches.kneerhof],
		assistants: [Coaches.temma, Coaches.bmarx],
		roster: [
			{
				number: 7,
				name: "Liam Burke",
				height: "5' 11\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 16,
				name: "Jayce Soto-Balin",
				height: "5' 5\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 1,
				name: "George Li",
				height: "5' 11\"",
				gradYear: "2025",
				school: "BASIS",
			},
			{
				number: 4,
				name: "Jacob Ocejo",
				height: "5' 10\"",
				gradYear: "2025",
				school: "Pueblo",
			},
			{
				number: 5,
				name: "Dylan Uribe",
				height: "5' 11\"",
				gradYear: "2025",
				school: "Mica Mountain",
			},
			{
				number: 3,
				name: "Evan Birtcil",
				height: "6' 0\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 6,
				name: "Om Patel",
				height: "5' 9\"",
				gradYear: "2024",
				school: "Marana",
			},
			{
				number: 8,
				name: "Johney Paschall",
				height: "5' 7\"",
				gradYear: "2025",
				school: "Veritas Academy",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 16-1
	{
		teamName: "16-1",
		age: 16,
		rank: 1,
		heads: [Coaches.bdenton],
		assistants: [Coaches.zdenton, Coaches.kboesen, Coaches.afelix],
		roster: [
			{
				number: 99,
				name: "Jayce Rooney",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 10,
				name: "Ryder Belik",
				height: "5' 7\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 13,
				name: "Ryan Guillot",
				height: "5' 11\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 25,
				name: "Jovian Villafane",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 88,
				name: "Connor Daggett",
				height: "6' 5\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 3,
				name: "Nicolas Sanchez",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				number: 4,
				name: "Cedric Whetten",
				height: "6' 3\"",
				gradYear: "2026",
				school: "Homeschool",
			},
			{
				number: 9,
				name: "Thomas Vamos",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 77,
				name: "Sam Savin",
				height: "5' 6\"",
				gradYear: "2026",
				school: "Catalina Foothils",
			},
			{
				number: 66,
				name: "Jakob Velasco",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 33,
				name: "Andrew Evans",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 15-1
	{
		teamName: "15-1",
		age: 15,
		rank: 1,
		heads: [Coaches.cdelavera],
		assistants: [Coaches.lrocha, Coaches.akeen, Coaches.frucker],
		roster: [
			{
				number: 11,
				name: "Andrew Keen",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 14,
				name: "Lehi Rocha",
				height: "6' 2\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 2,
				name: "Nolan Incontrera",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 3,
				name: "Julian Merheb",
				height: "6' 1\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 5,
				name: "Tyson Tippett",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 7,
				name: "Ethan Pinkney",
				height: "5' 7\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 9,
				name: "Chase Briggs",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 88,
				name: "Derek Zobrist-Carroll",
				height: "6' 5\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 99,
				name: "Jack Taras",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 6,
				name: "Frankie Maturo",
				height: "4' 10\"",
				gradYear: "2027",
				school: "Cienega",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 15-2
	{
		teamName: "15-2",
		age: 15,
		rank: 2,
		heads: [Coaches.kwells],
		assistants: [
			Coaches.jdevries,
			Coaches.fdonley,
			Coaches.sgillman,
			Coaches.bdevries,
		],
		roster: [
			{
				number: 1,
				name: "Malcolm Frank",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 99,
				name: "Ariston Elsesser",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 77,
				name: "Cuyler Zeh Plunkett",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 66,
				name: "Jameson Leary",
				height: "5' 8\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 37,
				name: "Smith King",
				height: "5' 10\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 8,
				name: "William Elliott",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Marana",
			},
			{
				number: 55,
				name: "Shane Palmer",
				height: "6' 2\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 44,
				name: "Gregan Vamos",
				height: "5' 8\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 30,
				name: "Ethan Belhumeur",
				height: "5' 7\"",
				gradYear: "2027",
				school: "Cienega ",
			},
			{
				number: 9,
				name: "Luke Evans",
				height: "5' 7\"",
				gradYear: "2027",
				school: "Salpointe ",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer 14-1
	{
		teamName: "14-1",
		age: 14,
		rank: 1,
		heads: [Coaches.lriel],
		assistants: [
			Coaches.amolina,
			Coaches.rbautista,
			Coaches.csmith,
			Coaches.lglasner,
		],
		roster: [
			{
				number: 2,
				name: "Vaughn Rooney",
				height: "5' 8\"",
				gradYear: "2028",
				school: "Orange Grove",
			},
			{
				number: 13,
				name: "Valentino Molina",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Old Vail",
			},
			{
				number: 14,
				name: "RJ Meza",
				height: "5' 2\"",
				gradYear: "2026",
				school: "St. Joseph",
			},
			{
				number: 16,
				name: "Xavier Molina",
				height: "5' 4\"",
				gradYear: "2028",
				school: "Old Vail",
			},
			{
				number: 18,
				name: "Peter Greer",
				height: "5' 10\"",
				gradYear: "2028",
				school: "Esperero Canyon",
			},
			{
				number: 3,
				name: "Lincoln Dalesandro",
				height: "6' 2\"",
				gradYear: "2028",
				school: "Salpointe",
			},
			{
				number: 36,
				name: "Amos King",
				height: "5' 9\"",
				gradYear: "2028",
				school: "Homeschool",
			},
			{
				number: 52,
				name: "James Merriman",
				height: "5' 5\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				number: 6,
				name: "Jael Soto-Perez",
				height: "5' 8\"",
				gradYear: "2024",
				school: "Esmond Station",
			},
			{
				number: 8,
				name: "Vincent Merheb",
				height: "5' 7\"",
				gradYear: "2028",
				school: "Esperero Canyon",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer Black
	{
		teamName: "Black",
		heads: [Coaches.jserna],
		assistants: [],
		roster: [
			{
				name: "James Marshall",
				height: "5' 8\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				name: "Jay Heisler",
				height: "5' 9\"",
				gradYear: "2026",
				school: "St. Augustine",
			},
			{
				name: "Cammeron Crowley",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				name: "Reyes Gardner",
				height: "5' 8\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				name: "Samson Goldberg",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				name: "Elijah Gutierrez",
				height: "5' 10\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				name: "Payton Needham",
				height: "6' \"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				name: "Sutton Heisey",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				name: "John Paul Caraveo",
				height: "5' 10\"",
				gradYear: "2025",
				school: "University",
			},
			{
				name: "Sullivan Morgenstern",
				height: "6' 0\"",
				gradYear: "2026",
				school: "BASIS",
			},
			{
				name: "Tyler Nitka",
				height: "6' 2\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				name: "Benjamin Teyechea",
				height: "5' 7\"",
				gradYear: "2026",
				school: "St. Augustine",
			},
			{
				name: "Josef Pletnick",
				height: "6'0\"",
				gradYear: "2026",
				school: "Canyon Del Oro",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer Blue
	{
		teamName: "Blue",
		heads: [Coaches.mvillegas],
		assistants: [],
		roster: [
			{
				name: "Zackary Yager",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Mica Mountain",
			},
			{
				name: "Gabriel Harmon",
				height: "5' 9\"",
				gradYear: "2026",
				school: "Tucson",
			},
			{
				name: "Kien McCoy",
				height: "5' 9\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				name: "Turhan Kamal",
				height: "5' 8\"",
				gradYear: "2025",
				school: "BASIS",
			},
			{
				name: "Tanner Larsen",
				height: "6' 1\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				name: "Reegan Wilson",
				height: "5' 9\"",
				gradYear: "2026",
				school: "Amphitheater",
			},
			{
				name: "Elijah Soto",
				height: "5' 2\"",
				gradYear: "2026",
				school: "Pueblo",
			},
			{
				name: "Ethan Lynch",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Tucson",
			},
			{
				name: "Patrick Sapp",
				height: "6' 0\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				name: "Sirgeo Flowers",
				height: "5' 5\"",
				gradYear: "2027",
				school: "Sahuaro",
			},
			{
				name: "Gabe Munger",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Mica Mountain",
			},
			{
				number: 33,
				name: "Jackson Buntin",
				height: "5' 6\"",
				gradYear: "2027",
				school: "Cienega",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},
	// Boys 2024 Summer Red
	{
		teamName: "Red",
		heads: [Coaches.bdevries],
		assistants: [],
		roster: [
			{
				name: "David Flores",
				height: "6' 1\"",
				gradYear: "2027",
				school: "Sahuaro",
			},
			{
				name: "Tyler Barr",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Homeschool",
			},
			{
				name: "Tyler Olsen",
				height: "6' 0\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				name: "Brandon Hain Basurto",
				height: "5' 10\"",
				gradYear: "2027",
				school: "Andrada Polytechnic",
			},
			{
				name: "Fin Keller",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				name: "Jack Pletnick",
				height: "5' 11\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
			{
				name: "Sam Wright",
				height: "6' 1\"",
				gradYear: "2028",
				school: "Ironwood Ridge",
			},
			{
				name: "Cooper Goffeney",
				height: "5' 6\"",
				gradYear: "2026",
				school: "Tucson",
			},
			{
				name: "Mason Chupp",
				height: "5' 11\"",
				gradYear: "2027",
				school: "IRHS",
			},
			{
				name: "Seamus McLeod",
				height: "5' 9\"",
				gradYear: "2027",
				school: "Mica Mountain",
			},
		],
		// image: {
		//   src: y2023t141b,
		// },
		season: "Boys",
		sub: "Summer",
		year: "23-24",
	},

	//GIRLS 24 18-1
	{
		teamName: "18-1",
		age: 18,
		rank: 1,
		heads: [Coaches.jdevries],
		assistants: [Coaches.nhelin],
		roster: [
			{
				number: 2,
				name: "Abigail Schurig",
				height: "5'10\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 8,
				name: "Adalyn Chue",
				height: "5'4\"",
				gradYear: "2026",
				school: "Rincon/University",
			},
			{
				number: 99,
				name: "Brynna Bowers",
				height: "5'7\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 3,
				name: "Camille Cabrera",
				height: "5'4\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 11,
				name: "Diamond Kodalen",
				height: "4'11\"",
				gradYear: "2026",
				school: "Sunnyside",
			},
			{
				number: 12,
				name: "Isabel  Nava-Rodriguez ",
				height: "5'1\"",
				gradYear: "2025",
				school: "Sunnyside",
			},
			{
				number: 13,
				name: "Jacqueline  Perez",
				height: "5'5\"",
				gradYear: "2026",
				school: "Rincon/University",
			},
			{
				number: 9,
				name: "Laura Quiroz",
				height: "5'9\"",
				gradYear: "2025",
				school: "Rincon/University",
			},
			{
				number: 10,
				name: "Mia Castro",
				height: "5'7\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 4,
				name: "Sienna Saenz",
				height: "6'0\"",
				gradYear: "2026",
				school: "Desert View",
			},
			{
				number: 5,
				name: "Sofia Franco",
				height: "5'7\"",
				gradYear: "2025",
				school: "Sunnyside",
			},
			{
				number: 6,
				name: "Sophia Cox",
				height: "5'2\"",
				gradYear: "2026",
				school: "The Gregory School",
			},
			{
				number: 1,
				name: "Tiffany Ortiz",
				height: "5'5\"",
				gradYear: "2024",
				school: "Cholla",
			},
		],
		image: {
			src: y2024t172,
		},
		season: "Girls",
		year: "23-24",
	},
	//GIRLS 24 17-1
	{
		teamName: "17-1",
		age: 17,
		rank: 1,
		heads: [Coaches.kwells],
		assistants: [Coaches.kneerhof, Coaches.eharper],
		roster: [
			{
				number: 7,
				name: "Alessandra Egginton",
				height: "5'11\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 11,
				name: "Alivia Sutton",
				height: "5'7\"",
				gradYear: "2026",
				school: "Tanque Verde",
			},
			{
				number: 12,
				name: "Anaij McCormick",
				height: "5'5\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 5,
				name: "Aurelise  De La Vara",
				height: "5'5\"",
				gradYear: "2026",
				school: "University",
			},
			{
				number: 21,
				name: "Danielle Carrasco ",
				height: "5'3\"",
				gradYear: "2025",
				school: "Tucson",
			},
			{
				number: 17,
				name: "Isabella Garcia",
				height: "5'4\"",
				gradYear: "2026",
				school: "Rincon/University",
			},
			{
				number: 15,
				name: "Khyra Superville",
				height: "5'3\"",
				gradYear: "2024",
				school: "Tucson",
			},
			{
				number: 2,
				name: "Lily Swanberg",
				height: "5'7\"",
				gradYear: "2026",
				school: "Tanque Verde",
			},
			{
				number: 10,
				name: "Marci Anderson ",
				height: "5'10\"",
				gradYear: "2025",
				school: "Marana",
			},
			{
				number: 3,
				name: "Mya Rollins",
				height: "5'4\"",
				gradYear: "2025",
				school: "Marana",
			},
			{
				number: 6,
				name: "Nevaeh Freeman",
				height: "5'3\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 9,
				name: "Iseima Amaya",
				height: "5'4\"",
				gradYear: "2025",
				school: "St. Augustine",
			},
		],
		image: {
			src: y2024t171,
		},
		season: "Girls",
		year: "23-24",
	},
	//GIRLS 24 15-1
	{
		teamName: "15-1",
		age: 15,
		rank: 1,
		heads: [Coaches.smcadams],
		assistants: [Coaches.sgillman, Coaches.alopez],
		roster: [
			{
				number: 31,
				name: "Alexia Adams",
				height: "5'4\"",
				gradYear: "2027",
				school: "Tanque Verde",
			},
			{
				number: 1,
				name: "Alexie Danna",
				height: "5'6\"",
				gradYear: "2027",
				school: "The Gregory School",
			},
			{
				number: 15,
				name: "Allison Cook",
				height: "5'2\"",
				gradYear: "2027",
				school: "Marana",
			},
			{
				number: 3,
				name: "Azalea Moraga",
				height: "5'2\"",
				gradYear: "2026",
				school: "Desert View",
			},
			{
				number: 16,
				name: "Camri Whitaker",
				height: "5'5\"",
				gradYear: "2027",
				school: "Ironwood Ridge",
			},
			{
				number: 10,
				name: "Jacquelyn Reardon",
				height: "5'8\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
			{
				number: 4,
				name: "Lillyana  Mitchell",
				height: "5'6\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 5,
				name: "Morgan  Fox",
				height: "5'3\"",
				gradYear: "2027",
				school: "Mountain View",
			},
			{
				number: 11,
				name: "Presley Jácome ",
				height: "5'5\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 7,
				name: "Sofia Schadt",
				height: "5'4\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
			{
				number: 6,
				name: "Sophia Maytorena ",
				height: "5'4\"",
				gradYear: "2027",
				school: "Marana",
			},
			{
				number: 2,
				name: "Sophia Washburn",
				height: "5'2\"",
				gradYear: "2026",
				school: "St. Augustine",
			},
		],
		image: {
			src: y2024t151,
		},
		season: "Girls",
		year: "23-24",
	},
	// GIRLS 24 15-2
	{
		teamName: "15-2",
		age: 15,
		rank: 2,
		heads: [Coaches.jserna],
		assistants: [Coaches.lmcadams],
		roster: [
			{
				number: 11,
				name: "Anahi Miranda Peregrina ",
				height: "5'5\"",
				gradYear: "2026",
				school: "Sahuaro",
			},
			{
				number: 30,
				name: "Anastazia Adkins",
				height: "5'3\"",
				gradYear: "2028",
				school: "The Gregory School",
			},
			{
				number: 1,
				name: "Chelsea Lopez",
				height: "5'3\"",
				gradYear: "2027",
				school: "Rincon/University",
			},
			{
				number: 23,
				name: "Dahlia Hotchkiss",
				height: "5'6\"",
				gradYear: "2026",
				school: "Tanque Verde",
			},
			{
				number: 2,
				name: "Dylan Patton",
				height: "5'6\"",
				gradYear: "2026",
				school: "Sahuaro",
			},
			{
				number: 3,
				name: "Emerson Myers",
				height: "5'11\"",
				gradYear: "2026",
				school: "The Gregory School",
			},
			{
				number: 15,
				name: "Fernanda Partida Guillen",
				height: "5'5\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 8,
				name: "Juliet Iadevaia",
				height: "5'1\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
			{
				number: 12,
				name: "Maya Rocha",
				height: "5'3\"",
				gradYear: "2027",
				school: "Rincon/University",
			},
			{
				number: 4,
				name: "Samantha Benson",
				height: "5'5\"",
				gradYear: "2026",
				school: "Sahuaro",
			},
			{
				number: 6,
				name: "Valeria Leon",
				height: "5'4\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
		],
		image: {
			src: y2024t152,
		},
		season: "Girls",
		year: "23-24",
	},
	// GIRLS 24 15-3
	{
		teamName: "15-3",
		age: 15,
		rank: 3,
		heads: [Coaches.mvillegas, Coaches.mburke],
		assistants: [Coaches.kboesen],
		roster: [
			{
				number: 41,
				name: "Amity Claggett",
				height: "5'4\"",
				gradYear: "2027",
				school: "ASU Prep",
			},
			{
				number: 4,
				name: "Hana Baldwin",
				height: "5'5\"",
				gradYear: "2027",
				school: "Calvary Chapel",
			},
			{
				number: 13,
				name: "Harlie Moffet",
				height: "5'4\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 9,
				name: "Isabella Mendibles",
				height: "5'3\"",
				gradYear: "2027",
				school: "Canyon Del Oro",
			},
			{
				number: 11,
				name: "Izzy  Garcia ",
				height: "5'3\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 17,
				name: "Madelynn Saenz",
				height: "5'10\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 2,
				name: "Melina Marin",
				height: "5'\"",
				gradYear: "2031",
				school: "Paulo Friere",
			},
			{
				number: 6,
				name: "Morgan Lamb",
				height: "5'1\"",
				gradYear: "2027",
				school: "Sahuaro",
			},
			{
				number: 3,
				name: "Olivia Little",
				height: "5'5\"",
				gradYear: "2027",
				school: "Sahuaro",
			},
			{
				number: 1,
				name: "Samara Rodriguez",
				height: "4'11\"",
				gradYear: "2027",
				school: "Desert Christian",
			},
			{
				number: 8,
				name: "Tanvi Patel",
				height: "5'3\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
		],
		image: {
			src: y2024t153,
		},
		season: "Girls",
		year: "23-24",
	},
	// GIRLS 24 14-1
	{
		teamName: "14-1",
		age: 14,
		rank: 1,
		heads: [Coaches.ahoover],
		assistants: [],
		roster: [
			{
				number: 19,
				name: "Amaya Chavez",
				height: "5'6\"",
				gradYear: "2028",
				school: "Cross",
			},
			{
				number: 2,
				name: "Ameliana  Herman",
				height: "5'0\"",
				gradYear: "2029",
				school: "Leman Academy",
			},
			{
				number: 4,
				name: "Brookelyn Earven",
				height: "5'2\"",
				gradYear: "2028",
				school: "Red Rock",
			},
			{
				number: 11,
				name: "Isabella Riggi",
				height: "5'6\"",
				gradYear: "2028",
				school: "Marana Middle",
			},
			{
				number: 13,
				name: "Julia Richards",
				height: "5'5\"",
				gradYear: "2028",
				school: "Marana Middle",
			},
			{
				number: 34,
				name: "Kayleigh Kennedy",
				height: "5'3\"",
				gradYear: "2028",
				school: "Legacy",
			},
			{
				number: 15,
				name: "Kinley Noggle",
				height: "5'2\"",
				gradYear: "2028",
				school: "Marana Middle",
			},
			{
				number: 3,
				name: "Liliana Tamietti",
				height: "5'0\"",
				gradYear: "2028",
				school: "Homeschool",
			},
			{
				number: 16,
				name: "Lilly Hardy",
				height: "5'3\"",
				gradYear: "2028",
				school: "Marana Middle",
			},
			{
				number: 1,
				name: "Lyxenia  Rocha",
				height: "5'3\"",
				gradYear: "2029",
				school: "St. Cyril",
			},
			{
				number: 5,
				name: "Tiffany Anderson",
				height: "5'10\"",
				gradYear: "2028",
				school: "Marana Middle",
			},
			{
				number: 6,
				name: "Zoey Morgan",
				height: "5'5\"",
				gradYear: "2028",
				school: "Roskruge",
			},
		],
		image: {
			src: y2024t141,
		},
		season: "Girls",
		year: "23-24",
	},
	// GIRLS 24 14-2
	{
		teamName: "14-2",
		age: 14,
		rank: 2,
		heads: [Coaches.ctofel],
		assistants: [Coaches.jbrambila],
		roster: [
			{
				number: 13,
				name: "Bianca Cheung",
				height: "5'4\"",
				gradYear: "2028",
				school: "Orange Grove",
			},
			{
				number: 1,
				name: "Brianna Abalos",
				height: "5'3\"",
				gradYear: "2029",
				school: "Tortolita",
			},
			{
				number: 6,
				name: "Cayden Palma",
				height: "5'1\"",
				gradYear: "2029",
				school: "The Gregory School",
			},
			{
				number: 2,
				name: "Emily Lopez",
				height: "5'1\"",
				gradYear: "2027",
				school: "Immaculate Heart",
			},
			{
				number: 10,
				name: "Madison Schaffer",
				height: "5'3\"",
				gradYear: "2028",
				school: "The Gregory School",
			},
			{
				number: 3,
				name: "Mia Achoukian",
				height: "5'1\"",
				gradYear: "2028",
				school: "Dodge",
			},
			{
				number: 4,
				name: "Renee-Sophia Raygoza ",
				height: "5'6\"",
				gradYear: "2028",
				school: "Old Vail",
			},
			{
				number: 21,
				name: "Riley  Zuckerman",
				height: "5'3\"",
				gradYear: "2029",
				school: "The Gregory School",
			},
			{
				number: 16,
				name: "Samara Schacht",
				height: "5'5\"",
				gradYear: "2028",
				school: "Cross",
			},
			{
				number: 32,
				name: "Tess Castillo",
				height: "5'3\"",
				gradYear: "2029",
				school: "Saints Peter & Paul",
			},
			{
				number: 35,
				name: "Jayleigh Diaz",
				height: "",
				gradYear: "",
				school: "",
			},
			{
				number: 36,
				name: "Maya Gasmen",
				height: "",
				gradYear: "",
				school: "",
			},
			// {
			//   name: "Veronica Cheung",
			//   height: "5'2\"",
			//   gradYear: "2028",
			//   school: "Orange Grove",
			// },
		],
		image: {
			src: y2024t142,
		},
		season: "Girls",
		year: "23-24",
	},

	/********/
	/* 2023 */
	/********/

	// BOYS Fall 23 18-1
	{
		teamName: "18-1",
		age: 18,
		rank: 1,
		heads: [Coaches.hmott],
		assistants: [Coaches.cmott],
		roster: [
			{
				number: 1,
				name: "Bryce Drummond",
				height: "6'3\"",
				gradYear: "2024",
				school: "University",
			},
			{
				number: 22,
				name: "Caiden Mott",
				height: "6'2\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 8,
				name: "Camden Soloway",
				height: "6'4\"",
				gradYear: "2024",
				school: "Ironwood Ridge",
			},
			{
				number: 2,
				name: "Dawson Briggs",
				height: "6'3\"",
				gradYear: "2025",
				school: "Cienega",
			},
			{
				number: 7,
				name: "Daymon Dumlao",
				height: "6'2\"",
				gradYear: "2024",
				school: "Ironwood Ridge",
			},
			{
				number: 17,
				name: "Dylan Rubio",
				height: "6'7\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 16,
				name: "Jackson Brown",
				height: "6'3\"",
				gradYear: "2024",
				school: "Cienega",
			},
			{
				number: 12,
				name: "Jacob Martinez ",
				height: "6'2\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 4,
				name: "Johan Brambila",
				height: "5'4\"",
				gradYear: "2024",
				school: "University",
			},
			{
				number: 3,
				name: "Wilson Spaulding",
				height: "6'3\"",
				gradYear: "2024",
				school: "Catalina Foothills",
			},
		],
		image: {
			src: y2023t181b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// BOYS Fall 23 18-2
	{
		teamName: "18-2",
		age: 18,
		rank: 1,
		heads: [Coaches.rbautista],
		assistants: [Coaches.ctofel, Coaches.sgillman],
		roster: [
			{
				number: 1,
				name: "Brevin Koch",
				height: "6'3\"",
				gradYear: "2024",
				school: "Tucson",
			},
			{
				number: 2,
				name: "Dawson Leber",
				height: "6'4\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 3,
				name: "Dominic Smith",
				height: "6'2\"",
				gradYear: "2024",
				school: "Ironwood Ridge",
			},
			{
				number: 4,
				name: "Ivan Dahl",
				height: "5'8\"",
				gradYear: "2024",
				school: "Ironwood Ridge",
			},
			{
				number: 54,
				name: "James Marshall",
				height: "5'8\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 16,
				name: "Jayce Soto-Balin",
				height: "5'5\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 10,
				name: "Kyle Senkerik",
				height: "6'4\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 22,
				name: "Leif Terre",
				height: "6'0\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 5,
				name: "Sammy Aston",
				height: "6'3\"",
				gradYear: "2025",
				school: "Cienega",
			},
			{
				number: 7,
				name: "Timothy Tran",
				height: "5'10\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 13,
				name: "Zakary Gruber",
				height: "6'3\"",
				gradYear: "2024",
				school: "Catalina Foothills",
			},
		],
		image: {
			src: y2023t182b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 17-1
	{
		teamName: "17-1",
		age: 18,
		rank: 1,
		heads: [Coaches.lrocha],
		assistants: [Coaches.mburke],
		roster: [
			{
				number: 3,
				name: "Evan Birtcil",
				height: "6'0\"",
				gradYear: "2025",
				school: "Ironwood Ridge",
			},
			{
				number: 6,
				name: "Forrest Kerwin",
				height: "5'10\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 4,
				name: "Gavin Green",
				height: "5'10\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 10,
				name: "Isak Jaime",
				height: "5'11\"",
				gradYear: "2025",
				school: "Catalina Foothills",
			},
			{
				number: 5,
				name: "Jairus  Walker",
				height: "6'4\"",
				gradYear: "2025",
				school: "Salpointe",
			},
			{
				number: 1,
				name: "John Paul Caraveo",
				height: "5'10\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 7,
				name: "Liam Burke",
				height: "5'11\"",
				gradYear: "2025",
				school: "University",
			},
			{
				number: 9,
				name: "Preston Stricker",
				height: "6'2\"",
				gradYear: "2025",
				school: "Sabino",
			},
			{
				number: 8,
				name: "Trevor Peyton",
				height: "5'9\"",
				gradYear: "2025",
				school: "University",
			},
			// {
			//   number: 11,
			//   name: "Xander  Lauretta",
			//   height: "6'1\"",
			//   gradYear: "2025",
			//   school: "University",
			// },
			{
				number: 34,
				name: "Eli Whaley",
				height: "6'2\"",
				gradYear: "2026",
				school: "Mountain View",
			},
		],
		image: {
			src: y2023t171b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 16-1
	{
		teamName: "16-1",
		age: 18,
		rank: 1,
		heads: [Coaches.shuff],
		assistants: [Coaches.avanhoesen],
		roster: [
			{
				number: 1,
				name: "Cammeron Crowley",
				height: "5'10\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 7,
				name: "Elijah Gutierrez ",
				height: "5'10\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				number: 2,
				name: "Israel Valencia",
				height: "5'10\"",
				gradYear: "2025",
				school: "Pueblo",
			},
			{
				number: 25,
				name: "Jovian Villafane",
				height: "5'10\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 3,
				name: "Nicolas Sanchez",
				height: "5'10\"",
				gradYear: "2026",
				school: "Rincon",
			},
			{
				number: 21,
				name: "Payton Needham",
				height: "6'\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 6,
				name: "Reyes Gardner",
				height: "5'8\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 13,
				name: "Ryan Guillot",
				height: "5'11\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 10,
				name: "Ryder Belik",
				height: "5'7\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 23,
				name: "Thor Haldorsen",
				height: "5'11\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 4,
				name: "Tyler Nitka",
				height: "6'1\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 32,
				name: "Jaden Clark",
				height: "6'2\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
		],
		image: {
			src: y2023t161b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 16-2
	{
		teamName: "16-2",
		age: 18,
		rank: 1,
		heads: [Coaches.jjantz],
		assistants: [Coaches.mvillegas],
		roster: [
			{
				number: 4,
				name: "Cedric Whetten",
				height: "6'1\"",
				gradYear: "2026",
				school: "Homeschool",
			},
			{
				number: 2,
				name: "Cristian Pino",
				height: "5'8\"",
				gradYear: "2025",
				school: "Sunnyside",
			},
			{
				number: 18,
				name: "Elijah Cohen",
				height: "5'9\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 11,
				name: "Ian Westover",
				height: "6'0\"",
				gradYear: "2026",
				school: "Mountain View",
			},
			{
				number: 6,
				name: "Josef Pletnick ",
				height: "6'0\"",
				gradYear: "2026",
				school: "Canyon Del Oro",
			},
			{
				number: 7,
				name: "Mateus Trierweiler",
				height: "5'7\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 5,
				name: "Oliver Scott",
				height: "6'1\"",
				gradYear: "2025",
				school: "Mountain View",
			},
			{
				number: 8,
				name: "Samson Goldberg",
				height: "5'10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 3,
				name: "Sutton Heisey",
				height: "6'0\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 9,
				name: "Thomas Vamos",
				height: "5'10\"",
				gradYear: "2026",
				school: "Catalina Foothills",
			},
			{
				number: 15,
				name: "Vaughan Lancaster",
				height: "5'10\"",
				gradYear: "2026",
				school: "Tucson Magnet",
			},
		],
		image: {
			src: y2023t162b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 16-3
	{
		teamName: "16-3",
		age: 18,
		rank: 1,
		heads: [Coaches.jdevries],
		assistants: [Coaches.jserna],
		roster: [
			{
				number: 1,
				name: "Malcolm Frank",
				height: "6'0\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 4,
				name: "Patrick Sapp",
				height: "5'10\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 6,
				name: "Tanner Larson",
				height: "6'0\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 9,
				name: "Luke Evans",
				height: "5'7\"",
				gradYear: "2026",
				school: "Salpointe",
			},
			{
				number: 23,
				name: "Kien McCoy",
				height: "5'8\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 30,
				name: "Ethan Belhumeur",
				height: "5'8\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 33,
				name: "Andrew Evans",
				height: "6'1\"",
				gradYear: "2026",
				school: "Mica Mountain",
			},
			{
				number: 37,
				name: "Smith King",
				height: "5'10\"",
				gradYear: "2027",
				school: "Homeschool",
			},
			{
				number: 38,
				name: "Peace King",
				height: "6'0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
			{
				number: 39,
				name: "Praise King",
				height: "6'0\"",
				gradYear: "2025",
				school: "Homeschool",
			},
		],
		image: {
			src: y2023t163b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 15-1
	{
		teamName: "15-1",
		age: 18,
		rank: 1,
		heads: [Coaches.kwells],
		assistants: [Coaches.ahoover],
		roster: [
			{
				number: 11,
				name: "Andrew Keen",
				height: "5'11\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 1,
				name: "Asher Taton",
				height: "5'8\"",
				gradYear: "2026",
				school: "Cienega",
			},
			{
				number: 9,
				name: "Chase  Briggs",
				height: "5'9\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 7,
				name: "Ethan Pinkney",
				height: "5'7\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 6,
				name: "Frankie Maturo",
				height: "5'2\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 3,
				name: "Julian Merheb",
				height: "6'1\"",
				gradYear: "2027",
				school: "Catalina Foothills",
			},
			{
				number: 14,
				name: "Lehi Rocha",
				height: "6'2\"",
				gradYear: "2027",
				school: "Salpointe",
			},
			{
				number: 2,
				name: "Nolan Incontrera",
				height: "5'6\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 10,
				name: "Tyler Drummond",
				height: "5'6\"",
				gradYear: "2027",
				school: "University",
			},
			{
				number: 5,
				name: "Tyson Tippett",
				height: "5'10\"",
				gradYear: "2027",
				school: "Cienega",
			},
			{
				number: 8,
				name: "William Elliott",
				height: "5'9\"",
				gradYear: "2027",
				school: "Marana",
			},
		],
		image: {
			src: y2023t151b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},
	// Boys Fall 23 14-1
	{
		teamName: "14-1",
		age: 18,
		rank: 1,
		heads: [Coaches.lriel],
		assistants: [Coaches.csmith],
		roster: [
			{
				number: 1,
				name: "Donovan Leber",
				height: "5'6\"",
				gradYear: "2027",
				school: "St Cyril",
			},
			{
				number: 2,
				name: "Dutch Leber",
				height: "5'0\"",
				gradYear: "2030",
				school: "St Cyril",
			},
			{
				number: 3,
				name: "Lincoln Dalesandro",
				height: "5'11\"",
				gradYear: "2028",
				school: "Esperero Middle School",
			},
			{
				number: 8,
				name: "Vincent Merheb",
				height: "5'7\"",
				gradYear: "2028",
				school: "Esperero Canyon",
			},
			{
				number: 10,
				name: "Noah Haubner",
				height: "5'6\"",
				gradYear: "2028",
				school: "Orange Grove",
			},
			{
				number: 13,
				name: "Valentino  Molina",
				height: "5'10\"",
				gradYear: "2028",
				school: "Old Vail",
			},
			{
				number: 16,
				name: "Xavier Molina",
				height: "5'4\"",
				gradYear: "2028",
				school: "Old Vail",
			},
			{
				number: 31,
				name: "Theo LeBlanc",
				height: "5'4\"",
				gradYear: "2028",
				school: "Esperero Canyon",
			},
			{
				number: 52,
				name: "James Merriman",
				height: "5'5\"",
				gradYear: "2029",
				school: "Esmond Station",
			},
			{
				name: "Amos King",
				height: "",
				gradYear: "2030",
				school: "Homeschool",
			},
			{
				number: 35,
				name: "Oliver Heald",
				height: "",
				gradYear: "",
				school: "",
			},
		],
		image: {
			src: y2023t141b,
		},
		season: "Boys",
		sub: "Fall",
		year: "23-24",
	},

	//GIRLS 23 16s
	{
		teamName: "16s",
		age: 18,
		rank: 1,
		heads: [Coaches.smcadams, Coaches.nhelin],
		assistants: [Coaches.ctofel],
		roster: [
			{ number: "5", name: "Aurelise A" },
			{ number: "21", name: "Dani C" },
			{ number: "11", name: "Alivia S", school: "Tanque Verde" },
			{ number: "6", name: "Sophia C" },
			{ number: "7", name: "Isabella G" },
			{ number: "1", name: "Rebecca R" },
			{ number: "12", name: "Isabel N" },
			{ number: "10", name: "Saira F" },
			{ number: "3", name: "Mya R" },
			{ number: "2", name: "Adalyn C", school: "RUHS" },
			{ number: "24", name: "Ale E", school: "Catalina Foothills" },
		],
		image: {
			src: y2023t16,
		},
		season: "Girls",
		year: "22-23",
	},
	//GIRLS 23 14s
	{
		teamName: "14s",
		age: 18,
		rank: 1,
		heads: [Coaches.ahoover],
		assistants: [Coaches.eevano, Coaches.njohnson],
		roster: [
			{ number: "7", name: "Jolie W" },
			{ number: "23", name: "Davne M" },
			{ number: "11", name: "Chelsea L" },
			{ number: "5", name: "Melina M" },
			{ number: "12", name: "Maya R" },
			{ number: "17", name: "Madelynn S" },
			{ number: "9", name: "Madison W" },
			{ number: "24", name: "Layla D" },
			{ number: "8", name: "Lola D" },
			{ number: "15", name: "Aubrey C" },
			{ number: "3", name: "Grace H" },
			{ number: "13", name: "Tiffany A" },
			{ number: "10", name: "Jacquelyn R" },
		],
		image: {
			src: y2023t14,
		},
		season: "Girls",
		year: "22-23",
	},
];

export { TeamList };
