import { useEffect, useState } from "react";

import { Dropdown } from "../../components/Dropdown";
import { Helmet } from "react-helmet";
import { TeamList } from "./TeamList";
import { TeamSlider } from "./TeamSlider";
import { useParams } from "react-router-dom";

function Teams() {
	let { selYear, selSeason, selTitle } = useParams();
	const selSub =
		selSeason && selSeason.includes("-") ? selSeason.split("-")[1] : null;
	selSeason =
		selSeason && selSeason.includes("-") ? selSeason.split("-")[0] : selSeason;

	const [slide, setSlide] = useState(0);

	// SEASONS
	const [season, setSeason] = useState("Girls");
	const seasons = [
		{ label: "Boys", value: "Boys" },
		{ label: "Girls", value: "Girls" },
	];
	const handleSeasonChange = (event) => {
		const thisSeason = event.target.value;
		setSeason(thisSeason);
		const theseYears = [
			...new Set(
				TeamList.filter((t) => t.season == thisSeason).map((t) => {
					return t.year;
				})
			),
		];
		setYear(theseYears[0]);
		setSlide(0);
	};

	// Years
	const [year, setYear] = useState("24-25");
	const yearsSet = [
		...new Set(
			TeamList.map((t) => {
				return t.year;
			})
		),
	];
	const years = yearsSet
		.filter((thisYear) =>
			TeamList.find(
				(t) =>
					t.season === (selSeason ?? season) && t.year == (selYear ?? thisYear)
			)
		)
		.map((y) => {
			return { label: y, value: y };
		});
	const handleYearChange = (event) => {
		setYear(event.target.value);
		setSub("Fall");
	};

	// Sub-Season (Fall, Summer, Spring) (Boys Only)
	const [sub, setSub] = useState("Fall");
	const subsSet = [
		...new Set(
			TeamList.map((t) => {
				return t.sub ?? null;
			})
		),
	].filter((s) => s !== null);
	const subs = subsSet
		.filter((thisSub) =>
			TeamList.find(
				(t) =>
					t.season === (selSeason ?? season) &&
					t.year == (selYear ?? year) &&
					t.sub == (selSub ?? thisSub)
			)
		)
		.map((y) => {
			return { label: y, value: y };
		});
	console.log("Subs:");
	console.log(subs);

	const handleSubChange = (event) => {
		setSub(event.target.value);
		setSlide(0);
	};

	useEffect(() => {
		// There are params that can be fed into the address (ex. '/23-24/Boys/Fall/17-1')
		// If there is a title param (ex. "17-1"), then we need to find that team
		let filteredTeams = TeamList;
		if (selSeason !== undefined) {
			setSeason(selSeason);

			// Filter all teams matching the requested season (ex. "Boys"):
			console.log("\n\nSEASON PARAM: " + selSeason);
			filteredTeams.filter((a) => a.season === selSeason);
			console.log("Matching Teams:");
			console.log(filteredTeams);
		}
		if (selTitle !== undefined) {
			if (selSub !== null) {
				setSub(selSub);

				// Filter all teams matching the requested sub-season (ex. "Spring"):
				console.log("SUB PARAM: " + selSub);
				filteredTeams = filteredTeams.filter((a) =>
					selSub ? a.sub === selSub : true
				);
				console.log("Matching Teams:");
				console.log(filteredTeams);
			}
			if (selYear !== undefined) {
				setYear(selYear);

				// Filter all teams matching the requested year (ex. "24-25"):
				console.log("\n\nYEAR PARAM: " + selYear);
				filteredTeams = filteredTeams.filter((a) =>
					selYear ? a.year === selYear : a.year === years[0].value
				);
				console.log("Matching Teams:");
				console.log(filteredTeams);
			} else {
				console.log("NO year, setting " + years[0].value);
				setYear(years[0].value);
			}

			// Find the team matching the requested team name (ex. "17-1"):
			console.log("\n\nTEAM PARAM: " + selTitle);
			const i = filteredTeams.findIndex((team) => team.teamName === selTitle);
			console.log(
				filteredTeams[i] !== undefined
					? "Found Matching Team:"
					: "No team found"
			);
			console.log(filteredTeams[i] !== undefined ? filteredTeams[i] : "");

			setSlide(i);

			window.scroll({ top: 200, left: 0, behavior: "smooth" });
		}

		return () => {};
	}, []);

	return (
		<div className="content">
			<Helmet>
				<title>Our Teams</title>
				<meta
					name="description"
					content="Our youth volleyball teams practice in Tucson, Arizon to compete around the country."
				/>
			</Helmet>
			<h1>Our Teams</h1>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Dropdown
					label="Year:"
					options={years}
					value={year}
					onChange={handleYearChange}
					key={year}
					hideLabel
				/>
				<Dropdown
					label="Season:"
					options={seasons}
					value={season}
					onChange={handleSeasonChange}
					key={season}
					hideLabel
				/>
				{season === "Boys" && subs.length > 1 ? (
					<Dropdown
						label="Sub:"
						options={subs}
						value={sub}
						onChange={handleSubChange}
						key={sub}
						hideLabel
					/>
				) : null}
			</div>

			<TeamSlider
				teams={TeamList.filter((a) => {
					return (
						a.season === season &&
						(season === "Boys" ? a.sub === sub : true) &&
						a.year === year
					);
				})}
				slide={slide}
				setSlide={setSlide}
			/>
		</div>
	);
}
export default Teams;
